'use strict'

exports.sendCookieConsent = consent => {
  return fetch('/api/cookie-consent', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      consent: consent
    })
  });
}