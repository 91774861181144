const cookieConsent = require('./modules/sendCookieConsent').sendCookieConsent;

if (document.querySelector('#cookie-accept')) {
  document.querySelector('#cookie-accept').addEventListener('click', e => {
    cookieConsent(true);
    //document.querySelector('#cookie-sticky').classList.add('cookie-sticky--hidden');
    document.querySelector('#cookie-consent-spacer').classList.add('cookie-consent-spacer--hidden');
    document.cookie = 'cookieConsent=true';

    let tagManager = document.createElement('script');
    tagManager.async = true;
    tagManager.src = 'https://www.googletagmanager.com/gtag/js?id=AW-824033656';
    document.querySelector('head').appendChild(tagManager);

    const scriptsToLoad = [
      `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-114981985-1', 'auto');
        ga('send', 'pageview');`,
      `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
         fbq('init', '102814117201753');
        fbq('track', 'PageView');`,
      `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

         gtag('config', 'AW-824033656');`
    ];

    scriptsToLoad.forEach(inlineScript => {
      let script = document.createElement('script');
      script.text = inlineScript;
      document.querySelector('head').appendChild(script);
    });
  });
}
